import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Button } from 'components'
import { usePopup } from 'hooks/usePopup'
import { FormEvent, useState } from 'react'
import { IPrintConfigResponse, printerConfigService } from 'services/printerConfigService'
import { Modal } from 'shared'

interface IProps {
  isOpen: boolean
  handleCloseModal: () => void
  data?: IPrintConfigResponse
  refresh: () => Promise<void>
}

const colorPrinters = [
  {
    name: 'AMARELO',
    color: '#FFFF00',
  },
  {
    name: 'AZUL',
    color: '#0000FF',
  },
  {
    name: 'VERDE',
    color: '#008000',
  },
  {
    name: 'TURQUESA',
    color: '	#40E0D0',
  },
  {
    name: 'SALMÃO',
    color: '	#FA8072',
  },
  {
    name: 'ROSA',
    color: '#FF1493',
  },
  {
    name: 'ROXO',
    color: '#A020F0',
  },
  {
    name: 'LARANJA',
    color: '#FF8C00',
  },
  {
    name: 'BRANCA',
    color: '#FFFFFF',
  },
]

export const sortedColorPrinters = colorPrinters.sort((a, b) => a.name.localeCompare(b.name))

export const PrinterManagementConfigUpdate = ({
  isOpen,
  handleCloseModal,
  data,
  refresh,
}: IProps) => {
  const { addPopup } = usePopup()
  const [colorRoute, setColorRoute] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const printerEdit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault()

    if (!colorRoute) {
      addPopup({
        type: 'error',
        title: 'Nenhuma impressora selecionada!',
      })
      return
    }

    if (data) {
      try {
        setIsLoading(true)
        await printerConfigService.updatePrinter(data.id as number, {
          newPrinterName: colorRoute,
        })
        addPopup({
          type: 'success',
          title: `Impressão de cor de rota ${data.color_route.toUpperCase()} alterada com sucesso`,
        })
        refresh()
        handleCloseModal()
      } catch (e: any) {
        addPopup({
          type: 'error',
          title: 'Erro ao alterar impressão de cor de rota',
          description: e?.message ?? '',
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <Modal
      title="Alterar destino de impressão da rota"
      handleClose={() => {
        handleCloseModal()
      }}
      open={isOpen}
    >
      <Box
        component="form"
        onSubmit={printerEdit}
        flexDirection="column"
        display="flex"
        alignItems="flex-end"
        style={{ gap: '1rem', marginTop: '1rem', height: '17rem' }}
        justifyContent="space-between"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel>Imprimir na impressora</InputLabel>
          <Select
            label="Imprimir na impressora"
            placeholder="Imprimir na impressora"
            onChange={e => setColorRoute(e.target.value as string)}
            value={colorRoute}
          >
            {colorPrinters.map(printer => (
              <MenuItem key={printer.name} value={printer.name}>
                {printer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" isLoading={isLoading}>
          Editar
        </Button>
      </Box>
    </Modal>
  )
}

export const PrinterManagementConfigCreate = ({ isOpen, handleCloseModal, refresh }: IProps) => {
  const { addPopup } = usePopup()
  const [colorRoute, setColorRoute] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const printerCreate = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault()

    if (!colorRoute) {
      addPopup({
        type: 'error',
        title: 'Nenhuma cor selecionada!',
      })
      return
    }
    try {
      setIsLoading(true)
      await printerConfigService.createPrinter({
        printerName: colorRoute.toUpperCase(),
        colorRoute: colorRoute.toUpperCase(),
      })
      addPopup({
        type: 'success',
        title: `Cor de rota ${colorRoute.toUpperCase()} criada com sucesso`,
      })
      refresh()
      handleCloseModal()
    } catch (e: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao criar a cor da rota',
        description: e?.message ?? '',
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Modal
      title="Criar cor de rota para impressão"
      handleClose={() => {
        handleCloseModal()
      }}
      open={isOpen}
    >
      <Box
        component="form"
        onSubmit={printerCreate}
        flexDirection="column"
        display="flex"
        alignItems="flex-end"
        style={{ gap: '1rem', marginTop: '1rem', height: '17rem' }}
        justifyContent="space-between"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel>Cor de rota</InputLabel>
          <Select
            label="Cor de rota"
            placeholder="Cor de rota"
            onChange={e => setColorRoute(e.target.value as string)}
            value={colorRoute}
          >
            {colorPrinters.map(printer => (
              <MenuItem key={printer.name} value={printer.name}>
                {printer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" isLoading={isLoading}>
          Criar
        </Button>
      </Box>
    </Modal>
  )
}
