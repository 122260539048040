import { Box, Typography, Tabs, Tab } from '@material-ui/core'
import { Button } from 'components/Button'
import { DownloadExcel } from 'components/ModalDownloadExcelLoading'
import { usePopup } from 'hooks/usePopup'
import { IPackingList, IResponsePackingDict } from 'interfaces/IPackingList'
import { ChangeEvent, useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { BsCircleFill } from 'react-icons/bs'
import { RiFileDownloadLine } from 'react-icons/ri'
import { packingList } from 'services/packingListServices'
import { Search } from 'shared'
import { debaunce } from 'utils'

import Accordion from './Accordion'
import { ButtonFilter, Header } from './styles'
import { ModalConfirmPrint } from 'components/pages/PackingList/ColorRoute/modalConfirmPrint'
import { IHandleEvent, IParams } from '..'

export interface IScreenPackingList {
  routes: IResponsePackingDict[]
  handleEvent: (param: IHandleEvent) => void
  params: IParams
  selectedDate: Date
}

const Desktop = ({ routes, handleEvent, params, selectedDate }: IScreenPackingList) => {
  const [openModalDownloadExcel, setOpenModalDownloadExcel] = useState(false)
  const [isloadingPrint, setIsLoadingPrint] = useState(false)
  const [openModalAlert, setOpenModealAlert] = useState({
    open: false,
    route: 0,
  })
  const [tab, setTab] = useState(0)
  const { addPopup } = usePopup()

  const handleSearch = (param: string) => {
    handleEvent({ param, type: 'search' })
  }

  const handleFilter = (param: string) => {
    handleEvent({ param, type: 'filter' })
  }

  const searchRoute = debaunce({ fn: handleSearch, delay: 200 })

  const toggleDownloadExcelBoxesRelation = () => {
    setOpenModalDownloadExcel(state => !state)
  }

  const handleManyPrints = async () => {
    setIsLoadingPrint(true)
    try {
      const routeData = routes
        .filter(({ route }) => !!JSON.parse(route.toString()))
        .map(route => ({
          route: route.route,
          driverName: route.orders[0]?.order_delivery_details?.driver,
          totalBoxes: route.orders.reduce((acc: number, order: IPackingList) => {
            const boxes =
              {
                true: order.corrected_boxes_number || 0,
                false: order.loading_boxes_number || 0,
              }[String(order.skipped)] ?? 0
            return acc + boxes
          }, 0),
        }))
      await packingList.printManyRoutes(routeData)

      addPopup({
        type: 'success',
        title: 'Impressão enviada! 🤠',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Deu erro pra mandar a impressão 😞',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoadingPrint(false)
    }
  }

  const handleOpenModalPrintWithoutScanner = (route: number) => {
    setOpenModealAlert({ open: true, route })
  }

  const handleCloseModalPrintWithoutScanner = () => {
    setOpenModealAlert({ open: false, route: 0 })
  }

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  return (
    <>
      <Header>
        <Search
          placeholder="Qual rota você busca?"
          style={{ width: '100%', maxWidth: '400px' }}
          onChange={e => {
            searchRoute(e.target.value)
          }}
        />
        <Box style={{ width: '100%', gap: '0.5rem' }}>
          <Typography>Filtrar:</Typography>
          <ButtonFilter
            colorIcon="#FFC107"
            isSelected={params.filter === 'pending'}
            onClick={() => handleFilter('pending')}
          >
            <BsCircleFill /> <span>Pendente</span>
          </ButtonFilter>
          <ButtonFilter
            colorIcon="#2E75FF"
            isSelected={params.filter === 'loaded'}
            onClick={() => handleFilter('loaded')}
          >
            <BsCircleFill />
            <span>Carregado</span>
          </ButtonFilter>
          <Box style={{ display: 'flex', gap: '0.5rem', marginLeft: 'auto' }}>
            <Button
              onClick={() => handleManyPrints()}
              variant="contained"
              startIcon={<AiFillPrinter size={20} />}
              disabled={isloadingPrint}
            >
              {isloadingPrint ? 'Imprimindo' : 'Imprimir todos'}
            </Button>
            <Button
              onClick={toggleDownloadExcelBoxesRelation}
              variant="contained"
              startIcon={<RiFileDownloadLine size={20} />}
            >
              Baixar planilha
            </Button>
          </Box>
        </Box>
      </Header>

      <Tabs
        value={tab}
        onChange={handleTabChange}
        centered
        style={{ marginTop: '1rem', marginBottom: '2rem' }}
      >
        <Tab label="Aguardando Impressão" />s
        <Tab label="Impressos" />
      </Tabs>

      <div>
        {tab === 0 && (
          <Box display="flex" flexDirection="column" style={{ gap: '12px', marginTop: '20px' }}>
            {routes.map(item => {
              if (item.printed) return null
              return (
                <Accordion
                  item={item}
                  handleEvent={handleEvent}
                  key={item.route}
                  handlePrintWithoutScanner={() => handleOpenModalPrintWithoutScanner(item.route)}
                />
              )
            })}
          </Box>
        )}
        {tab === 1 && (
          <Box display="flex" flexDirection="column" style={{ gap: '12px', marginTop: '20px' }}>
            <Typography style={{ fontSize: '1.2rem', color: '#c4c4c4', fontWeight: 500 }}>
              Rotas Impressas
            </Typography>
            {routes.map(item => {
              if (!item.printed) return null
              return (
                <Accordion
                  item={item}
                  handleEvent={handleEvent}
                  key={item.route}
                  handlePrintWithoutScanner={() => handleOpenModalPrintWithoutScanner(item.route)}
                />
              )
            })}
          </Box>
        )}
      </div>

      <DownloadExcel
        isOpen={openModalDownloadExcel}
        handleCloseModal={toggleDownloadExcelBoxesRelation}
        selectedDate={selectedDate}
      />
      <ModalConfirmPrint
        route={openModalAlert.route}
        open={openModalAlert.open}
        handleClose={handleCloseModalPrintWithoutScanner}
      />
    </>
  )
}

export default Desktop
