import { getDeliveryDate } from 'utils/dateNow'

import { ILoadingConference } from '../interfaces/ILoadingConference'
import { IPaginationParams, IPaginationResponse } from '../interfaces/IPagination'
import { api, ApiService } from './api'

interface IConfirmLoadingConference {
  message: string
  info_order: IOrderInfo
}

export type IOrderInfo = {
  message: string
  status: string
  loading_boxes_number: number
  order_id: string
  route: number
  conference_boxes_number: number
  updated: Date
  client_name_or_trade: string
  client_type: string
  color_route: string
}

class LoadingConference {
  constructor(private readonly api: ApiService) {}

  async getAll(
    params: IPaginationParams,
    signal?: AbortSignal,
  ): Promise<IPaginationResponse<ILoadingConference>> {
    const { day, month, year } = getDeliveryDate()

    const search = params.search
    if (search) {
      return this.api.get(carambolaUrl + '/loading-conference/search', {
        params: {
          ...params,
          scheduledDate: `${year}-${month}-${day}`,
        },
        signal,
      })
    }
    return this.api.get(carambolaUrl + '/loading-conference/progress', {
      params: {
        ...params,
        scheduledDate: `${year}-${month}-${day}`,
      },
      signal,
    })
  }

  async getAllOrderBy(
    params: { orderBy: string },
    signal?: AbortSignal,
  ): Promise<ILoadingConference[]> {
    const { day, month, year } = getDeliveryDate()
    return await this.api.get(carambolaUrl + '/loading-conference/', {
      params: {
        ...params,
        scheduledDate: `${year}-${month}-${day}`,
      },
      signal,
    })
  }

  async getById(id: string, signal?: AbortSignal) {
    const { results } = await this.api.get(carambolaUrl + `/loading-conference/search`, {
      params: {
        orderId: id,
      },
    })
    return results[0] as ILoadingConference
  }

  async updateBoxe(id: string, loadingBoxesNumber: number) {
    return this.api.patch(carambolaUrl + `/loading-conference/change/${id}`, {
      loadingBoxesNumber,
    })
  }

  async updateAllBoxes() {
    return this.api.patch(carambolaUrl + `/loading-conference/change/0`, {
      updateAllBoxes: true,
    })
  }

  async updateBoxesOrder(id: string) {
    return this.api.put(carambolaUrl + `/loading-conference/change/${id}`)
  }

  async confirmLoadingBox(id: string): Promise<IConfirmLoadingConference> {
    return this.api.put(carambolaUrl + `/loading-conference/change/${id}`, {
      isFixScanner: true,
    })
  }

  async downloadExcelCountBoxes(
    conveyorBeltNotConsidered: boolean,
    conferenceNotConsidered: boolean,
    scheduledDate?: string,
  ) {
    return this.api.get(carambolaUrl + `/loading-conference/order-boxes-registration/download`, {
      responseType: 'blob',
      params: {
        conveyorBeltNotConsidered: conveyorBeltNotConsidered || undefined,
        conferenceNotConsidered: conferenceNotConsidered || undefined,
        scheduledDate,
      },
    })
  }

  async downloadExcelRoutesResume(scheduledDate?: string) {
    return this.api.get(carambolaUrl + `/loading-conference/routes-resume/download`, {
      responseType: 'blob',
      params: {
        scheduledDate,
      },
    })
  }

  update(id: number, data: Partial<ILoadingConference>) {
    return this.api.patch(`${carambolaUrl}/loading-conference/loading-conference/${id}`, data)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const loadingConference = new LoadingConference(api)
