import { Box, Typography } from '@material-ui/core'
import { Button, ContainerPage } from 'components'
import { ReturnArea } from 'components/pages/Config/'
import { usePopup } from 'hooks/usePopup'
import { useEffect, useRef, useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { IPrintConfigResponse, printerConfigService } from 'services/printerConfigService'

import { IModalConfirm, ModalDeleteConfirm } from './ModalConfirm'
import { PrinterCard } from './PrinterCard'
import {
  sortedColorPrinters,
  PrinterManagementConfigUpdate,
  PrinterManagementConfigCreate,
} from './PrinterManagementConfig'
import { useStyles } from './styles'

interface IModalUpdate {
  isOpen: boolean
  data: IPrintConfigResponse
}

interface IModalCreate {
  isOpen: boolean
}

export const PrinterManagement = () => {
  const classes = useStyles()
  const modalDeleteConfirmRef = useRef<IModalConfirm>(null)
  const { addPopup } = usePopup()
  const [printers, setPrinters] = useState<IPrintConfigResponse[]>([])
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<IModalUpdate>({
    isOpen: false,
    data: {},
  } as IModalUpdate)
  const [isModalCreateOpen, setIsModalCreateOpen] = useState<IModalCreate>({
    isOpen: false,
  } as IModalCreate)

  const handleCloseModalUpdate = () =>
    setIsModalUpdateOpen({ isOpen: false, data: {} } as IModalUpdate)
  const handleCloseModalCreate = () => setIsModalCreateOpen({ isOpen: false } as IModalCreate)

  const getPrinters = async () => {
    try {
      const data = await printerConfigService.getPrinter()
      setPrinters(data)
    } catch {
      addPopup({ title: 'Erro ao buscar impressoras', type: 'error' })
    }
  }

  useEffect(() => {
    getPrinters()
  }, [])

  return (
    <>
      <ContainerPage>
        <ReturnArea title="Gerenciamento de impressoras" />

        <Box className={classes.boxAlign}>
          <Typography className={classes.titlePage}>
            Gerenciar impressão das cores de rotas
          </Typography>

          <Button
            onClick={() => setIsModalCreateOpen({ isOpen: true })}
            className={classes.configs}
            style={{ textTransform: 'none' }}
            variant="contained"
          >
            <AiFillPrinter size={'2rem'} style={{ marginRight: '0.6rem' }} />
            Nova cor de rota
          </Button>
        </Box>
        <Box className={classes.containner}>
          {printers.map(printer => {
            const color = sortedColorPrinters.find(
              ({ name }) => name === printer.printer_name?.toUpperCase(),
            )
            return (
              <Box key={printer.id} style={{ marginRight: '2rem', marginTop: '2rem' }}>
                <PrinterCard
                  handleConfirmDelete={() => {
                    modalDeleteConfirmRef.current?.setData(printer)
                    modalDeleteConfirmRef.current?.toggleOpenModal()
                  }}
                  handleOpenEdit={() => {
                    setIsModalUpdateOpen({ isOpen: true, data: printer })
                  }}
                  background={color?.color ?? '#FFFFFF'}
                  title={
                    printer.color_route === 'BRANCA'
                      ? `${printer.color_route.toUpperCase()} (SEM ROTA)`
                      : `ROTA ${printer.color_route.toUpperCase()}`
                  }
                />
              </Box>
            )
          })}
        </Box>
      </ContainerPage>
      <PrinterManagementConfigUpdate
        refresh={getPrinters}
        isOpen={isModalUpdateOpen.isOpen}
        data={isModalUpdateOpen.data}
        handleCloseModal={handleCloseModalUpdate}
      />
      <PrinterManagementConfigCreate
        refresh={getPrinters}
        isOpen={isModalCreateOpen.isOpen}
        handleCloseModal={handleCloseModalCreate}
      />
      <ModalDeleteConfirm ref={modalDeleteConfirmRef} refresh={getPrinters} />
    </>
  )
}

export default PrinterManagement
