import { Box, IconButton, MenuItem, Typography } from '@material-ui/core'
import { useRef, useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { FaUsersCog } from 'react-icons/fa'
import { IoMdSettings } from 'react-icons/io'
import { MenuPopover } from 'shared'

import { useStyles } from './styles'

interface IProps {
  title: string
  background: string
  handleOpenEdit: () => void
  handleConfirmDelete: () => void
}

export const PrinterCard = ({ title, background, handleOpenEdit, handleConfirmDelete }: IProps) => {
  const classes = useStyles({ background })
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const iconRef = useRef(null)

  const toggleMenu = () => setIsMenuOpen(state => !state)

  return (
    <Box className={classes.container}>
      <Box className={classes.box}>
        <Box className={classes.boxIcon}>
          <AiFillPrinter size="25px" color={background === '#FFFFFF' ? '#808080' : '#FFFFFF'} />
        </Box>
        <Box className={classes.boxInformations}>
          <Typography className={classes.boxTitle}>{title}</Typography>
        </Box>
      </Box>

      <IconButton onClick={toggleMenu} ref={iconRef}>
        <IoMdSettings size={'1.5rem'} style={{ color: '#757575' }} />
      </IconButton>
      <MenuPopover open={isMenuOpen} onClose={toggleMenu} anchorEl={iconRef.current}>
        <MenuItem onClick={handleOpenEdit}>Editar</MenuItem>
        <MenuItem onClick={handleConfirmDelete}>Excluir</MenuItem>
      </MenuPopover>
    </Box>
  )
}
