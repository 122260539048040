import { Box, Typography } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Button as MuiButton } from 'components'
import { usePopup } from 'hooks/usePopup'
import {
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  FormEvent,
} from 'react'
import { IPrintConfig, printerConfigService } from 'services/printerConfigService'
import { Modal } from 'shared'

import { useStyles } from './styles'

const Button = styled(MuiButton)({
  width: '9.375rem',
  padding: '0.6rem',
})

export interface IModalConfirm {
  toggleOpenModal: () => void
  setData: (x: IPrintConfig) => void
}

interface Props {
  refresh: () => Promise<void>
}

const Component: ForwardRefRenderFunction<IModalConfirm, Props> = ({ refresh }, ref) => {
  const { addPopup } = usePopup()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<IPrintConfig>({} as IPrintConfig)
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  const toggleOpenModal = () => setIsOpen(state => !state)

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    toggleOpenModal()
  }

  const handleConfirm = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault()

    try {
      setIsLoading(true)
      await printerConfigService.deletePrinter(data.id as number)
      refresh()
      addPopup({
        type: 'success',
        title: `Cor de rota excluida com sucesso`,
      })
      handleClose()
    } catch (e: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao excluir cor de rota',
        description: e?.message ?? '',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useImperativeHandle(ref, () => ({
    toggleOpenModal: handleOpenModal,
    setData,
  }))

  return (
    <Modal open={isOpen} handleClose={handleClose} title="Excluir impressão de cor de rota">
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Typography>{data?.printerName}</Typography>
        <Typography style={{ fontWeight: 600 }}>Deseja excluir esta cor de rota?</Typography>
      </Box>

      <Box display="flex" justifyContent="center" style={{ gap: '1rem' }} mt="3rem">
        <Button
          variant="outlined"
          isLoading={isLoading}
          onClick={() => handleClose()}
          className={classes.buttonSeeItens}
        >
          Cancelar
        </Button>

        <Button variant="contained" color="secondary" isLoading={isLoading} onClick={handleConfirm}>
          Confirmar
        </Button>
      </Box>
    </Modal>
  )
}

export const ModalDeleteConfirm = forwardRef(Component)
