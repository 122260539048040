import { api as apiService, ApiService } from './api'

export interface IPrintConfig {
  id?: number
  created?: Date
  updated?: Date
  colorRoute?: string
  newPrinterName?: string
  printerName?: string
  updatedBy?: string
}

export interface IPrintConfigResponse {
  id?: number
  created?: Date
  updated?: Date
  color_route: string
  printer_name?: string
  updated_by?: string
}

class PrinterConfigService {
  constructor(private readonly api: ApiService) {}

  public async createPrinter(data: IPrintConfig): Promise<IPrintConfigResponse> {
    return this.api.post(`${carambolaUrl}/separation-settings/printer-management`, data)
  }

  public async getPrinter(): Promise<IPrintConfigResponse[]> {
    return await this.api.get(`${carambolaUrl}/separation-settings/printer-management`)
  }

  public async updatePrinter(id: number, data: IPrintConfig): Promise<IPrintConfigResponse> {
    return this.api.patch(`${carambolaUrl}/separation-settings/printer-management/${id}`, data)
  }

  public async deletePrinter(id: number): Promise<{ message: string }> {
    return this.api.delete(`${carambolaUrl}/separation-settings/printer-management/${id}`)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA

export const printerConfigService = new PrinterConfigService(apiService)
