import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  buttonSeeItens: {
    color: '#757575',
  },
})

export const styles = makeStyles(theme => ({
  headerAlign: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  filterAlign: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    alignItems: 'center',
    marginBottom: '0.8rem',
  },
  downloadBotton: {
    display: 'flex',
  },
  buttonFilter: {
    minWidth: '6.5rem',
    height: '1.7rem',
    background: '#F4F4F4',
    borderRadius: '2.5rem',
    marginLeft: '1rem',
    border: 'none',
  },
  buttonFilterActive: {
    minWidth: '6.5rem',
    height: '1.7rem',
    background: '#F4F4F4',
    borderRadius: '2.5rem',
    marginLeft: '1rem',
    border: '1px solid #757575',
  },
  buttonAlign: {
    marginRight: '0.5rem',
  },
  buttonText: {
    color: '#757575',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.89rem',
    lineHeight: '1rem',
  },
  search: {
    width: '21rem',
    [theme.breakpoints.between(0, 587)]: {
      marginBottom: '1.5rem',
      width: '100%',
    },
  },
  BoxConference: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    height: '3.5rem',
    maxWidth: '26.5rem',
    width: '100%',
    borderRadius: '1.5rem',
    padding: '0.937rem 1.93rem',
    marginBottom: '2rem',
    marginTop: '2.5rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '1.32rem',
    [theme.breakpoints.between(0, 570)]: {
      maxWidth: '16.5rem',
      fontSize: '0.8rem',
    },
  },
}))

export const ShowItemsStyles = makeStyles({
  itemDetail: {
    color: '#414141',
    fontSize: '1rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.3rem',
  },
})

export const ItemDetailsStyles = makeStyles({
  itemImage: {
    width: '3rem',
  },
  itemInformations: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.9rem',
    lineHeight: '1rem',
    color: '#353535',
    marginLeft: '10px',
  },
  separator: {
    borderTop: '0.5px solid #C4C4C4',
    marginTop: '0.7rem',
  },
})
