import { makeStyles } from '@material-ui/core/styles'
import { backgroundImages } from 'polished'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '23.5rem',
    height: '8rem',
    background: '#FFFFFF',
    padding: '0.7rem',
    borderRadius: '0.7rem',
    boxShadow:
      '0px 3px 3px rgba(160, 160, 160, 0.14), 0px 3px 4px rgba(160, 160, 160, 0.12), 0px 1px 8px rgba(160, 160, 160, 0)',
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginLeft: '1rem',
  },
  boxInformations: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.5rem',
  },
  boxTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.1rem',
    lineHeight: '1.37rem',
    letterSpacing: '0.01em',
    color: '#353535',
  },
  boxIcon: {
    background: ({ background }: any) => background,
    width: '3.125rem',
    height: '3.125rem',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: ({ background }: any) =>
      `1px solid ${background === '#FFFFFF' ? '#808080' : background}`,
  },
})
