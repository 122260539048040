import { Tab, Tabs, Box, Typography, Divider, FormControlLabel, styled } from '@material-ui/core'
import { Button } from 'components'
import { usePopup } from 'hooks/usePopup'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { ChangeEvent, useState } from 'react'
import { loadingConference } from 'services/loadingConferenceService'
import { Checkbox, Modal } from 'shared'

import { useStyles } from './styles'

interface IProps {
  isOpen: boolean
  handleCloseModal: () => void
  selectedDate: Date
}

const LabelStyle = styled(Typography)({
  color: '#353535',
  textAlign: 'left',
  userSelect: 'none',
  fontSize: '1.1rem',
  '& span': {
    display: 'block',
    color: '#757575',
    fontSize: '0.95rem',
  },
})

export const DownloadExcel = ({ isOpen, handleCloseModal, selectedDate }: IProps) => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const [disregardConveyorBelt, setDisregardConveyorBelt] = useState(false)
  const [disregardConference, setDisregardConference] = useState(false)
  const [tab, setTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const handleDownloadExcelCountBoxes = async () => {
    setIsLoading(true)
    try {
      const data = await loadingConference.downloadExcelCountBoxes(
        disregardConveyorBelt,
        disregardConference,
        moment(selectedDate).format('YYYY-MM-DD'),
      )
      fileDownload(data, 'Contagem_de_caixas.xls')
      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Opa, o download foi solicitado com sucesso 🤠',
        description: 'E esse copão aí?!',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao tentar fazer o download 😢',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownloadExcelRoutesResume = async () => {
    setIsLoading(true)
    try {
      const data = await loadingConference.downloadExcelRoutesResume(
        moment(selectedDate).format('YYYY-MM-DD'),
      )
      fileDownload(data, 'Resumo_de_rotas.xls')
      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Opa, o download foi solicitado com sucesso 🤠',
        description: 'E esse copão aí?!',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao tentar fazer o download 😢',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal title="Baixar uma planilha? Simbora! 🤠" handleClose={handleCloseModal} open={isOpen}>
      <Tabs value={tab} onChange={handleChangeTab} centered>
        <Tab label="Contagem de caixas" />
        <Tab label="Resumo de rotas" />
      </Tabs>
      <Divider style={{ margin: '1rem 0' }} />
      {tab === 0 && (
        <Box>
          <Typography className={classes.subTitle}>
            Quer desconsiderar a contagem em algum dos nossos processos? É só marcar.
          </Typography>
          <Box display="flex" justifyContent="space-between" mt="2rem">
            <FormControlLabel
              label={<LabelStyle>📦 Sim, da esteira</LabelStyle>}
              control={
                <Checkbox
                  color="primary"
                  checked={disregardConveyorBelt}
                  onChange={e => setDisregardConveyorBelt(e.target.checked)}
                  disabled={isLoading}
                />
              }
            />
            <FormControlLabel
              label={<LabelStyle>🔎 Sim, dos conferentes</LabelStyle>}
              control={
                <Checkbox
                  color="primary"
                  checked={disregardConference}
                  onChange={e => setDisregardConference(e.target.checked)}
                  disabled={isLoading}
                />
              }
            />
          </Box>
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={handleDownloadExcelCountBoxes}
            disabled={isLoading}
          >
            <Typography className={classes.buttonText}>Pode baixar!</Typography>
          </Button>
        </Box>
      )}
      {tab === 1 && (
        <Box>
          <Typography className={classes.subTitle}>
            Opa, aqui num tem segredo nenhum, é só baixar!
          </Typography>
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={handleDownloadExcelRoutesResume}
            disabled={isLoading}
          >
            <Typography className={classes.buttonText}>Pode baixar!</Typography>
          </Button>
        </Box>
      )}
    </Modal>
  )
}
